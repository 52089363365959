<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline is-vcentered">
        <div class="column is-12 is-flex-desktop is-align-items-center">
          <p class="paragraph has-text-weight-bold mr-6 my-2">
            ESTATUS: <span :class="getStatusColor(statusId)">{{ statusName }}</span>
          </p>
          <button class="button button_micro_dark_outlined my-2" @click="$emit('set-moda-sale-status')">
            CAMBIAR ESTATUS
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const PENDIENTE = 1
const EN_PROCESO = 2
const ENVIADO = 3

export default {
  name: 'StatusSale',
  props: {
    statusId: {
      type: Number,
      default: null
    },
    statusName: {
      type: String,
      default: null
    }
  },
  methods: {
    getStatusColor (statusId) {
      if (statusId === PENDIENTE) {
        return 'text_yellow'
      } else if (statusId === EN_PROCESO) {
        return 'text_aqua_ligth'
      } else if (statusId === ENVIADO) {
        return 'text_green'
      } else {
        return 'text_cancel'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }

  @media screen and (max-width: 768px) {
    .button_micro_dark_outlined {
      width: 100%;
    }
  }
</style>
